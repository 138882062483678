import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { sso } from "../libs/react-mpk/hocs";
import { PageWrapper } from "../libs/react-mpk/wrapper";
import {
  Dashboard,
  FeatureOrder,
  Topup,
  Stamping,
  Paid,
  DmsPaid,
  Notifications,
  Profile,
  LogExport,
  ProductTemplate,
  ReportReferalCode,
  ReferalCode,
  PaymentDocument,
  DmsOrder,
} from "../modules";
import "../libs/react-mpk/styles/var.scss";
import { Chip, FontIcon } from "react-md";

const Internal = ({ history, navigationStore, authStore, envStore }) => {
  return (
    <PageWrapper
      sidebarCollapsible={true}
      inverseTheme={true}
      showFilterMenu={false}
      sidebarHeader={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: 20,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 10,
              border: "2px solid #000",
              borderRadius: 50,
              padding: 5,
            }}
          >
            <FontIcon
              className="mdi mdi-account"
              style={{
                fontSize: "30pt",
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="mpk-margin-XS margin-top mpk-font size-XL weight-B">
              {authStore.user.name}
            </span>
          </div>
        </div>
      }
      showNewsTicker={false}
      sidebarMenu={[
        {
          children: [
            {
              label: "Dashboard",
              index: "dashboard, dashboard",
              resourceUri: "",
              iconClassName: "mdi mdi mdi-view-dashboard-outline",
              path: "/internal/dashboard",
              childPath: [],
              key: "DASHBOARD",
            },
          ],
        },
        {
          label: "Report",
          keyList: ["REPORT"],
          iconClassName: "mdi mdi mdi-clipboard-text-outline",
          children: [
            {
              label: "Dokumen",
              index: "dokumen, dokumen",
              resourceUri: "",
              path: "/internal/report/dokumen",
              childPath: [],
              key: "DOKUMEN",
            },
            {
              label: "Feature Order",
              index: "feature",
              resourceUri: "",
              path: "/internal/report/feature-order",
              childPath: [],
              key: "FEATURE_ORDER",
            },
            {
              label: "Topup",
              index: "topup, topup",
              resourceUri: "",
              path: "/internal/report/topup",
              childPath: [],
              key: "TOPUP",
            },
            {
              label: "DMS Order",
              index: "dms",
              resourceUri: "",
              path: "/internal/report/dms-order",
              childPath: [],
              key: "DMS_ORDER",
            },
            // {
            //   label: "Payment Template",
            //   index: "paymentTemplate, paymentTemplate",
            //   resourceUri: "",
            //   path: "/internal/report/payment-template",
            //   childPath: [],
            //   key: "PAYMENTTEMPLATE",
            // },
            // {
            //   label: "E-Meterai Paid",
            //   index: "paid, paid",
            //   resourceUri: "",
            //   path: "/internal/report/paid",
            //   childPath: [],
            //   key: "PAID",
            // },
            // {
            //   label: "DMS Paid",
            //   index: "paid, paid",
            //   resourceUri: "",
            //   path: "/internal/report/dms-paid",
            //   childPath: [],
            //   key: "PAID",
            // },
            {
              label: "Kode Referal",
              index: "referal, referal",
              resourceUri: "",
              path: "/internal/report/referal-code",
              childPath: [],
              key: "REFERAL",
            },
          ],
        },
        {
          label: "Template Document",
          keyList: ["TEMPLATE DOCUMENT"],
          iconClassName: "mdi mdi mdi-clipboard-text-outline",
          children: [
            {
              label: "Product",
              index: "product, product",
              resourceUri: "",
              path: "/internal/template-document/product",
              childPath: [],
              key: "Product",
            },
          ],
        },
        {
          children: [
            {
              label: "Master Kode Referal",
              index: "kode, kode",
              resourceUri: "",
              iconClassName: "mdi mdi-cube-outline",
              path: "/internal/referal-code",
              childPath: [],
              key: "KODE",
            },
          ],
        },
        // {
        //   children: [
        //     {
        //       label: "History Notification",
        //       index: "notification, notification",
        //       resourceUri: "",
        //       iconClassName: "mdi mdi mdi-bell-outline",
        //       path: "/internal/notification",
        //       childPath: [],
        //       key: "NOTIFICATION",
        //     },
        //   ],
        // },
        {
          children: [
            {
              label: "Log Export",
              index: "logExport, logExport",
              resourceUri: "",
              iconClassName: "mdi mdi mdi-clock-outline",
              path: "/internal/log-export",
              childPath: [],
              key: "LOGEXPORT",
            },
          ],
        },
      ]}
    >
      <Router history={history}>
        <Switch>
          <Route
            path="/internal/dashboard"
            render={(props) => <Dashboard {...props} />}
          />
          <Route
            path="/internal/report/dokumen"
            render={(props) => <Stamping {...props} />}
          />
          <Route
            path="/internal/report/feature-order"
            render={(props) => <FeatureOrder {...props} />}
          />
          <Route
            path="/internal/report/topup"
            render={(props) => <Topup {...props} />}
          />
          <Route
            path="/internal/report/dms-order"
            render={(props) => <DmsOrder {...props} />}
          />
          <Route
            path="/internal/report/payment-template"
            render={(props) => <PaymentDocument {...props} />}
          />
          <Route
            path="/internal/report/paid"
            render={(props) => <Paid {...props} />}
          />
          <Route
            path="/internal/report/dms-paid"
            render={(props) => <DmsPaid {...props} />}
          />
          <Route
            path="/internal/report/referal-code"
            render={(props) => <ReportReferalCode {...props} />}
          />
          <Route
            path="/internal/referal-code"
            render={(props) => <ReferalCode {...props} />}
          />
          <Route
            path="/internal/notification"
            render={(props) => <Notifications {...props} />}
          />
          <Route
            path="/internal/log-export"
            render={(props) => <LogExport {...props} />}
          />
          <Route
            path="/internal/profile"
            render={(props) => <Profile {...props} />}
          />
          <Route
            path="/internal/template-document/product"
            render={(props) => <ProductTemplate {...props} />}
          />
        </Switch>
      </Router>
    </PageWrapper>
  );
};

// export default inject(
//   "authStore",
//   "temporaryStore",
//   "envStore",
//   "modalStore",
//   "navigationStore"
// )(observer(Internal));

export default sso({
  basePath: "/internal",
})(Internal);
