import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import topupService from "./Topup.service";
import { FontIcon, Button, Chip } from "react-md";
import ErrorService from "../../../services/errorService";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { DataForm } from "../../../libs/react-mpk/components";
import LoadingOverlay from "react-loading-overlay";
import payment from "./payment.json";
import TopupReport from "./TopupReportForm";

const Topup = ({
  className = "",
  showCommandbar = true,
  modalStore,
  history,
  ...props
}) => {
  const basePath = "topup";
  const baseId = "mod-topup";
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [fetchParam, setFetchParam] = useState([]);
  const [method, setMethod] = useState(null);
  const [data, setData] = useState([]);
  let [search, setSearch] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const fetchGetDetailTopup = async (id) => {
    setPloading({ ...ploading, loading: true });
    try {
      let res = await topupService.getTopupById(id);
      setPloading({ ...ploading, loading: false });
      return res.data;
    } catch (e) {
      // toastError("error", e);
      setPloading({ ...ploading, loading: false });
    }
  };

  var actions = [
    {
      label: `${t.translate("column.export")}`,
      iconClassName: "mdi mdi-file-export",
      onClick: async () => {
        setShowForm(true);
      },
    },
  ];

  var itemActions = [
    new TableWrapper.action(
      "Get Status Topup",
      "mdi mdi-arrow-down-bold-circle",
      async (item) => {
        const detailTopup = await fetchGetDetailTopup(item.id);
        setTimeout(() => {
          if (props.isPicker) {
            document.getElementsByClassName("mdi-reload")[1].click();
          } else {
            document.getElementsByClassName("mdi-reload")[0].click();
          }
        }, 1000);
        modalStore.showInfo({
          title: "",
          children: <Chip className="chip-success">{detailTopup.status}</Chip>,
        });
      },
      (item) => {
        return item.status === "ORDER" || item.status === "PENDING";
      }
    ),
    new TableWrapper.action(
      t.translate("word.view"),
      "mdi mdi-eye",
      async (item) => {
        window.open(item.invoiceUrl);
      },
      true
    ),
  ];

  const onCloseForm = () => {
    setShowForm(false);
    setSelectedItem(null);
  };

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
        <TableWrapper
          selectable={true}
          useFilter={false}
          baseId={baseId}
          title={t.translate(`modules.${basePath}.title`)}
          className={className}
          commandbar={{
            style: {
              backgroundColor: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              marginBottom: 15,
            },
          }}
          defaultData={[]}
          defaultSortBy="date"
          sideHeader={
            <DataForm
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName="mdi mdi-magnify"
              submitLabel={`${t.translate("word.search")}`}
              onBeforeChange={(key, value, parentKey) => {
                if (key === "paymentMethod.equals") setMethod(value);
                return value;
              }}
              additionalAction={[
                {
                  show: true,
                  render: () => (
                    <Button
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        setSearch({});
                        setMethod(null);
                        localStorage.removeItem(`${baseId}-saved-search`);
                        setTimeout(() => {
                          if (props.isPicker) {
                            document
                              .getElementsByClassName("mdi-reload")[1]
                              .click();
                          } else {
                            document
                              .getElementsByClassName("mdi-reload")[0]
                              .click();
                          }
                        }, 1000);
                      }}
                    >
                      {t.translate("word.delete")}
                    </Button>
                  ),
                },
              ]}
              onSubmit={(values, callback) => {
                if (values["createdDate.greaterThanOrEqual"]) {
                  const createdDate = values["createdDate.greaterThanOrEqual"];
                  values["createdDate.greaterThanOrEqual"] = new Date(
                    `${createdDate} 00:00:00`
                  ).toISOString();
                  values["createdDate.lessThanOrEqual"] = new Date(
                    `${createdDate} 23:59:59`
                  ).toISOString();
                }
                if (values["paidAt.greaterThanOrEqual"]) {
                  const paidAt = values["paidAt.greaterThanOrEqual"];
                  values["paidAt.greaterThanOrEqual"] = new Date(
                    `${paidAt} 00:00:00`
                  ).toISOString();
                  values["paidAt.lessThanOrEqual"] = new Date(
                    `${paidAt} 23:59:59`
                  ).toISOString();
                }

                setSearch(values);
                localStorage.setItem(
                  `${baseId}-saved-search`,
                  JSON.stringify(search)
                );
                setTimeout(() => {
                  if (props.isPicker) {
                    document.getElementsByClassName("mdi-reload")[1].click();
                  } else {
                    document.getElementsByClassName("mdi-reload")[0].click();
                  }
                  callback("", false, false, false);
                }, 1000);
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div
                        className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                      >
                        <p>{t.translate(`word.searching`)}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.SELECT,
                  label: `Product`,
                  key: "productName.equals",
                  type: "text",
                  options: [
                    {
                      label: "e-Meterai",
                      value: "emeterai peruri",
                    },
                    {
                      label: "e-Sign",
                      value: "esign vinotek",
                    },
                  ],
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.id`
                  )}`,
                  key: "id.contains",
                  type: "text",
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.invoiceId`
                  )}`,
                  key: "invoiceId.contains",
                  type: "text",
                },
                {
                  inputType: inputTypes.SELECT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.paymentMethod`
                  )}`,
                  key: "paymentMethod.equals",
                  type: "text",
                  options: Object.keys(payment).map((v) => {
                    return {
                      label: v,
                      value: v,
                    };
                  }),
                },
                {
                  inputType: inputTypes.SELECT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.paymentType`
                  )}`,
                  key: "paymentType.equals",
                  type: "text",
                  options: method
                    ? payment[method].map((v) => {
                        return {
                          label: v,
                          value: v,
                        };
                      })
                    : Object.values(payment)
                        .flat()
                        .map((v) => {
                          return {
                            label: v,
                            value: v,
                          };
                        }),
                },
                {
                  inputType: inputTypes.SELECT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.status`
                  )}`,
                  key: "status.equals",
                  type: "text",
                  options: [
                    {
                      label: "PAID",
                      value: "PAID",
                    },
                    {
                      label: "ORDER",
                      value: "ORDER",
                    },
                    {
                      label: "PENDING",
                      value: "PENDING",
                    },
                    {
                      label: "EXPIRED",
                      value: "EXPIRED",
                    },
                  ],
                },
                {
                  inputType: inputTypes.DATEPICKER,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.createdAt`
                  )}`,
                  key: "createdDate.greaterThanOrEqual",
                  type: "text",
                },
                {
                  inputType: inputTypes.DATEPICKER,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.paidAt`
                  )}`,
                  key: "paidAt.greaterThanOrEqual",
                  type: "text",
                },
              ]}
            />
          }
          columns={[
            {
              label: "Produk",
              searchable: true,
              sortable: true,
              key: "id",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.product.name,
            },
            {
              label: t.translate("column.id"),
              searchable: true,
              sortable: true,
              key: "id",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.id,
            },
            {
              label: t.translate("column.invoiceId"),
              searchable: true,
              sortable: true,
              key: "invoiceId",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.invoiceId,
            },
            {
              label: t.translate("column.paymentType"),
              searchable: true,
              sortable: true,
              key: "paymentType",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.paymentType,
            },
            {
              label: t.translate("column.status"),
              key: "status",
              sortable: true,
              searchable: true,
              render: (d) => {
                if (d.status === "PAID") {
                  return <Chip className="chip-success">{d.status}</Chip>;
                } else {
                  return <Chip className="chip-error">{d.status}</Chip>;
                }
              },
            },
            {
              label: t.translate("column.qty"),
              searchable: true,
              sortable: true,
              key: "qty",
              type: TableWrapper.dataTypes.NUMBER,
              render: (item) => item.qty,
            },
            {
              label: t.translate("column.totalPrice"),
              searchable: true,
              sortable: true,
              key: "totalPrice",
              type: TableWrapper.dataTypes.NUMBER,
              render: (item) => `Rp${item.totalPrice}`,
            },
            {
              label: t.translate("column.paidAt"),
              searchable: true,
              sortable: true,
              key: "paidAt",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.paidAt,
            },
            {
              label: t.translate("column.createdDate"),
              searchable: true,
              sortable: true,
              key: "createdDate",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.createdDate,
            },
            {
              label: t.translate("column.createdBy"),
              searchable: true,
              sortable: true,
              key: "createdBy",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.createdBy,
            },
          ]}
          actions={actions}
          itemActions={itemActions}
          onFetchData={(params) => {
            return new Promise(async (resolve, reject) => {
              try {
                params = {
                  ...search,
                  ...params,
                  sort: "createdDate,DESC",
                };
                if (params.search) {
                  params.size = 9999;
                }
                var getPage = null;

                getPage = await topupService.getTopup(params);

                setFetchParam(params);
                setData(getPage.data);
                getPage.headers["x-pagination-count"] =
                  getPage.headers["x-total-count"];
                resolve(getPage);
              } catch (e) {
                setFetchParam(params);
                resolve();
                ErrorService(e);
              }
            });
          }}
          showCommandbar={showCommandbar}
          showFilterPeriod={true}
        />
      </LoadingOverlay>
      <TopupReport
        visible={showForm}
        onRequestClose={() => {
          onCloseForm();
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("envStore", "modalStore")(observer(Topup));
