import React, { useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import stampingService from "./Stamping.service";
import { FontIcon, Button, Chip, List, ListItem } from "react-md";
import ErrorService from "../../../services/errorService";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { DataForm } from "../../../libs/react-mpk/components";
import LoadingOverlay from "react-loading-overlay";
import { convertUtcToCurrentUserTime } from "../../../utilities";

const Stamping = ({
  className = "",
  showCommandbar = true,
  modalStore,
  history,
  ...props
}) => {
  const basePath = "document";
  const baseId = "mod-document";
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [fetchParam, setFetchParam] = useState([]);
  const [data, setData] = useState([]);
  let [search, setSearch] = useState({});

  var actions = [];

  var itemActions = [
    new TableWrapper.action(
      t.translate("word.view"),
      "mdi mdi-eye",
      async (item) => {
        const {
          sn,
          docFilename,
          docDate,
          docNo,
          docPage,
          docType,
          message,
          status,
        } = item;
        const data = {
          sn,
          status,
          message,
          docFilename,
          docDate,
          docNo,
          docPage,
          docType,
        };
        modalStore.showInfo({
          title: ``,
          width: 1000,
          children: (
            <>
              <List>
                {Object.keys(data).map((key) => {
                  return (
                    <ListItem
                      key={key}
                      primaryText={t.translate(`column.${key}`)}
                      secondaryText={data[key]}
                    ></ListItem>
                  );
                })}
              </List>
            </>
          ),
        });
      },
      true
    ),
  ];

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
        <TableWrapper
          selectable={true}
          useFilter={false}
          baseId={baseId}
          title={t.translate(`modules.${basePath}.title`)}
          className={className}
          commandbar={{
            style: {
              backgroundColor: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              marginBottom: 15,
            },
          }}
          defaultData={[]}
          defaultSortBy="date"
          sideHeader={
            <DataForm
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName="mdi mdi-magnify"
              submitLabel={`${t.translate("word.search")}`}
              additionalAction={[
                {
                  show: true,
                  render: () => (
                    <Button
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        setSearch({});
                        localStorage.removeItem(`${baseId}-saved-search`);
                        setTimeout(() => {
                          if (props.isPicker) {
                            document
                              .getElementsByClassName("mdi-reload")[1]
                              .click();
                          } else {
                            document
                              .getElementsByClassName("mdi-reload")[0]
                              .click();
                          }
                        }, 1000);
                      }}
                    >
                      {t.translate("word.delete")}
                    </Button>
                  ),
                },
              ]}
              onSubmit={(values, callback) => {
                if (values["doc.equals"]) {
                  values["stamp.equals"] =
                    values["doc.equals"] === "e-Meterai & e-Sign"
                      ? true
                      : values["doc.equals"] === "e-Meterai"
                      ? true
                      : false;
                  values["sign.equals"] =
                    values["doc.equals"] === "e-Meterai & e-Sign"
                      ? true
                      : values["doc.equals"] === "e-Sign"
                      ? true
                      : false;
                }
                if (values["createdDate.greaterOrEqualThan"])
                  values["createdDate.greaterOrEqualThan"] = new Date(
                    values["createdDate.greaterOrEqualThan"]
                  );
                if (values["createdDate.lessOrEqualThan"])
                  values["createdDate.lessOrEqualThan"] = new Date(
                    values["createdDate.lessOrEqualThan"]
                  );
                setSearch(values);
                localStorage.setItem(
                  `${baseId}-saved-search`,
                  JSON.stringify(search)
                );
                setTimeout(() => {
                  if (props.isPicker) {
                    document.getElementsByClassName("mdi-reload")[1].click();
                  } else {
                    document.getElementsByClassName("mdi-reload")[0].click();
                  }
                  callback("", false, false, false);
                }, 1000);
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div
                        className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                      >
                        <p>{t.translate(`word.searching`)}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.SELECT,
                  label: `Stamp`,
                  key: "doc.equals",
                  type: "text",
                  options: [
                    {
                      label: "e-Meterai & e-Sign",
                      value: "e-Meterai & e-Sign",
                    },
                    {
                      label: "e-Meterai",
                      value: "e-Meterai",
                    },
                    {
                      label: "e-Sign",
                      value: "e-Sign",
                    },
                  ],
                },
                {
                  inputType: inputTypes.SELECT,
                  label: `Status e-Meterai`,
                  key: "status.equals",
                  type: "text",
                  options: [
                    {
                      label: "COMPLETED",
                      value: "COMPLETED",
                    },
                    {
                      label: "DRAFT",
                      value: "DRAFT",
                    },
                    {
                      label: "NEED_RETRY",
                      value: "NEED_RETRY",
                    },
                  ],
                },
                {
                  inputType: inputTypes.SELECT,
                  label: `Status e-Sign`,
                  key: "signStatus.equals",
                  type: "text",
                  options: [
                    {
                      label: "NONE",
                      value: "NONE",
                    },
                    {
                      label: "PROCESS",
                      value: "PROCESS",
                    },
                    {
                      label: "COMPLETED",
                      value: "COMPLETED",
                    },
                    {
                      label: "FAILED",
                      value: "FAILED",
                    },
                    {
                      label: "FAILED_OTP",
                      value: "FAILED_OTP",
                    },
                  ],
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.sn`
                  )}`,
                  key: "sn.contains",
                  type: "text",
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.docFilename`
                  )}`,
                  key: "docFilename.contains",
                  type: "text",
                },
              ]}
            />
          }
          columns={[
            {
              label: "Dokumen",
              key: "stamp",
              sortable: true,
              searchable: true,
              render: (item) => {
                if (item.stamp && item.sign) {
                  return (
                    <Chip className="chip-success">e-Meterai & e-Sign</Chip>
                  );
                } else if (item.stamp) {
                  return (
                    <Chip
                      className="chip-success"
                      style={{ backgroundColor: "blue" }}
                    >
                      e-Meterai
                    </Chip>
                  );
                } else {
                  return (
                    <Chip
                      className="chip-error"
                      style={{ backgroundColor: "purple", color: "white" }}
                    >
                      e-Sign
                    </Chip>
                  );
                }
              },
            },
            {
              label: "Status e-Meterai",
              key: "status",
              sortable: true,
              searchable: true,
              render: (item) => {
                if (item.stamp) {
                  if (item.status === "COMPLETED") {
                    return <Chip className="chip-success">{item.status}</Chip>;
                  } else {
                    return <Chip className="chip-error">{item.status}</Chip>;
                  }
                } else return "-";
              },
            },
            {
              label: "Status e-Sign",
              key: "signStatus",
              sortable: true,
              searchable: true,
              render: (item) => {
                if (item.sign) {
                  if (item.signStatus === "COMPLETED") {
                    return (
                      <Chip className="chip-success">{item.signStatus}</Chip>
                    );
                  } else {
                    return (
                      <Chip className="chip-error">{item.signStatus}</Chip>
                    );
                  }
                } else return "-";
              },
            },
            {
              label: t.translate("column.sn"),
              searchable: true,
              sortable: true,
              key: "sn",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => (item.stamp ? item.sn : "-"),
            },
            {
              label: t.translate("column.docFilename"),
              searchable: true,
              sortable: true,
              key: "docFilename",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.docFilename,
            },
            {
              label: t.translate("column.docDate"),
              searchable: true,
              sortable: true,
              key: "docDate",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.docDate,
            },
            {
              label: "Pesan e-Meterai",
              searchable: true,
              sortable: true,
              key: "message",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.message,
            },
            {
              label: "Lokasi",
              // searchable: true,
              // sortable: true,
              key: "location",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.signLocation,
            },
            // {
            //   label: 'Pesan e-Sign',
            //   searchable: true,
            //   sortable: true,
            //   key: "message",
            //   type: TableWrapper.dataTypes.STRING,
            //   render: (item) => item.message,
            // },
            {
              label: t.translate("column.createdDate"),
              searchable: true,
              sortable: true,
              key: "createdDate",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.createdDate,
            },
            {
              label: t.translate("column.createdBy"),
              searchable: true,
              sortable: true,
              key: "createdBy",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.createdBy,
            },
          ]}
          actions={actions}
          itemActions={itemActions}
          onFetchData={(params) => {
            return new Promise(async (resolve, reject) => {
              try {
                params = {
                  ...search,
                  ...params,
                  sort: "createdDate,DESC",
                };
                if (params.search) {
                  params.size = 9999;
                }
                var getPage = null;

                getPage = await stampingService.getStamping(params);

                setFetchParam(params);
                setData(getPage.data);
                getPage.headers["x-pagination-count"] =
                  getPage.headers["x-total-count"];
                resolve(getPage);
              } catch (e) {
                setFetchParam(params);
                resolve();
                ErrorService(e);
              }
            });
          }}
          showCommandbar={showCommandbar}
          showFilterPeriod={true}
        />
      </LoadingOverlay>
    </>
  );
};

export default inject("envStore", "modalStore")(observer(Stamping));
