import React, { useEffect, useState } from "react";
import { CustomWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import "./Dashboard.scss";
import {
  Card,
  CardContent,
  CardTitle,
  Dialog,
  useToggle,
  Button,
  CircularProgress,
} from "react-md";
import { inject, observer } from "mobx-react";
import LoadingOverlay from "react-loading-overlay";
import { toastError } from "../../libs/react-mpk/services/sweetToast.service";
import service from "./Dashboard.service";
import { format } from "../../libs/react-mpk/services/number.service";
import stampingService from "../Report/Stamping/Stamping.service";
import Chart from "../../components/Chart";
import { DataForm } from "../../libs/react-mpk/components";
import { inputTypes } from "../../libs/react-mpk/config/constant";

const Dashboard = ({ authStore }) => {
  const [quotaEmet, setQuotaEmet] = useState(0);
  const [totalTopupEmet, setTotalTopupEmet] = useState(0);
  const [totalStampingEmet, setTotalStampingEmet] = useState(0);
  const [dataChart, setDataChart] = useState([]);
  const [filter, setFilter] = useState({});
  const [visible, enable, disable] = useToggle(false);

  const [loadingSaldoKuotaEmet, setLoadingSaldoKuotaEmet] = useState(true);
  const [loadingTotalTopupEmet, setLoadingTotalTopupEmet] = useState(true);
  const [loadingStampingEmet, setLoadingStampingEmet] = useState(true);
  const [loadingUsageOrderEmet, setLoadingUsageOrderEmet] = useState(true);

  const [totalRegisterEsign, setTotalRegisterEsign] = useState(0);
  const [totalTotalUserEsign, setTotalTotalUserEsign] = useState(0);
  const [totalTopupEsign, setTotalTopupEsign] = useState(0);
  const [totalStampingEsign, setTotalStampingEsign] = useState(0);

  const [loadingTotalTopupEsign, setLoadingTotalTopupEsign] = useState(true);
  const [loadingStampingEsign, setLoadingStampingEsign] = useState(true);
  const [loadingTotalUserEsign, setLoadingTotalUserEsign] = useState(true);

  const [totalDmsOrderTotalLifetime, setTotalDmsOrderTotalLifetime] =
    useState(0);
  const [totalDmsOrderTotal, setTotalDmsOrderTotal] = useState(0);

  const [loadingDmsOrderTotalLifetime, setLoadingDmsOrderTotalLifetime] =
    useState(true);
  const [loadingDmsOrderTotal, setLoadingDmsOrderTotal] = useState(true);

  const fetchSaldoKuotaEmet = async () => {
    setLoadingSaldoKuotaEmet(true);
    try {
      let res = await service.getSaldo();
      setQuotaEmet(res.data);
      setLoadingSaldoKuotaEmet(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingSaldoKuotaEmet(false);
    }
  };

  const fetchTotalTopupEmet = async () => {
    setLoadingTotalTopupEmet(true);
    try {
      let res = await service.getTotalTopup({
        "status.equals": "PAID",
        "productName.equals": "emeterai peruri",
      });
      setTotalTopupEmet(res.data);
      setLoadingTotalTopupEmet(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingTotalTopupEmet(false);
    }
  };

  const fetchStampingEmet = async () => {
    setLoadingStampingEmet(true);
    try {
      let res = await service.getTotalStamp({
        "status.equals": "COMPLETED",
      });
      // console.log(res, "res");
      setTotalStampingEmet(res.data);
      setLoadingStampingEmet(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingStampingEmet(false);
    }
  };

  const fetchUsageOrder = async (params) => {
    setLoadingUsageOrderEmet(true);
    // try {
    //   let res = await service.UsageOrder(params);
    //   setDataChart(res.data);
    //   setLoadingUsageOrderEmet(false);
    // } catch (e) {
    //   toastError("error", e);
    //   setLoadingUsageOrderEmet(false);
    // }
  };

  const fetchTotalUserEsign = async () => {
    setLoadingTotalUserEsign(true);
    try {
      let res = await service.getTotalUser({
        "psreVinotekStatusRegister.in": [
          "PERMOHONAN",
          "ONGOING",
          "DALAM_PROSES",
          "DITOLAK",
          "DITERBITKAN",
          "DICABUT",
          "KEDALUARSA",
          "SELESAI",
        ],
      });
      // console.log(res, "ressss");
      setTotalTotalUserEsign(res.data);
      setLoadingTotalUserEsign(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingTotalUserEsign(false);
    }
  };

  const fetchTotalTopupEsign = async () => {
    setLoadingTotalTopupEsign(true);
    try {
      let res = await service.getTotalTopup({
        "status.equals": "PAID",
        "productName.equals": "esign vinotek",
      });
      setTotalTopupEsign(res.data);
      setLoadingTotalTopupEsign(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingTotalTopupEsign(false);
    }
  };

  const fetchStampingEsign = async () => {
    setLoadingStampingEsign(true);
    try {
      let res = await service.getTotalStamp({
        "signStatus.equals": "COMPLETED",
      });
      // console.log(res, "res");
      setTotalStampingEsign(res.data);
      setLoadingStampingEsign(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingStampingEsign(false);
    }
  };

  const fetchTotalDmsOrderTotalLifetime = async () => {
    setLoadingDmsOrderTotalLifetime(true);
    try {
      let res = await service.getTotalDmsOrderTotalLifetime();
      setTotalDmsOrderTotalLifetime(res.data);
      setLoadingDmsOrderTotalLifetime(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingDmsOrderTotalLifetime(false);
    }
  };

  const fetchTotalDmsOrderTotal = async () => {
    setLoadingDmsOrderTotal(true);
    try {
      let res = await service.getTotalDmsOrderTotal({
        "paymentStatus.equals": "PAID",
      });
      setTotalDmsOrderTotal(res.data);
      setLoadingDmsOrderTotal(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingDmsOrderTotal(false);
    }
  };

  const fetchAll = () => {
    fetchSaldoKuotaEmet();
    fetchTotalTopupEmet();
    fetchStampingEmet();
    fetchUsageOrder();

    fetchTotalTopupEsign();
    fetchStampingEsign();
    fetchTotalUserEsign();

    fetchTotalDmsOrderTotalLifetime();
    fetchTotalDmsOrderTotal();
  };
  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <LoadingOverlay spinner text="Loading your content...">
      <CustomWrapper
        baseId="mod-dashboard-id"
        title="Dashboard"
        className="mpk-full full-height"
        styleCommandBar={{
          backgroundColor: "white",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
        actions={[
          {
            label: t.translate("mpk.column.reload"),
            iconClassName: "mdi mdi-reload",
            onClick: () => {
              fetchAll();
            },
          },
          {
            label: `${t.translate("mpk.column.filter")} ${t.translate(
              "word.orderUsage"
            )}`,
            iconClassName: "mdi mdi-filter-outline",
            onClick: () => enable(),
          },
        ]}
        children={
          <>
            <div className="column">
              <CardTitle style={{ paddingTop: 20, paddingBottom: 20 }}>
                e-Meterai (Peruri)
              </CardTitle>
              <div className="row">
                <Card className="card-saldo-emeterai">
                  <CardTitle className="card-title">Saldo Peruri</CardTitle>
                  <CardContent className="card-content">
                    {loadingSaldoKuotaEmet ? (
                      <CircularProgress centered={false} small={true} />
                    ) : (
                      format(quotaEmet.saldo)
                    )}
                  </CardContent>
                </Card>
                <div style={{ padding: "0 10px 0 10px" }} />
                <Card className="card-saldo-emeterai">
                  <CardTitle className="card-title">
                    {t.translate("word.totalTopup")}
                  </CardTitle>
                  <CardContent className="card-content">
                    {loadingTotalTopupEmet ? (
                      <CircularProgress centered={false} small={true} />
                    ) : (
                      format(totalTopupEmet)
                    )}
                  </CardContent>
                </Card>
                <div style={{ padding: "0 10px 0 10px" }} />
                <Card className="card-saldo-emeterai">
                  <CardTitle className="card-title">
                    {t.translate("word.totalStamping")}
                  </CardTitle>
                  <CardContent className="card-content">
                    {loadingStampingEmet ? (
                      <CircularProgress centered={false} small={true} />
                    ) : (
                      format(totalStampingEmet)
                    )}
                  </CardContent>
                </Card>
              </div>
              <CardTitle style={{ paddingTop: 20, paddingBottom: 20 }}>
                e-Sign (Vinotek)
              </CardTitle>
              <div className="row">
                <Card className="card-saldo-esign">
                  <CardTitle className="card-title">Saldo Vinotek</CardTitle>
                  <CardContent className="card-content">
                    {/* {loadingSaldoKuotaEmet ? (
                      <CircularProgress centered={false} small={true} />
                    ) : (
                      format(quotaEmet.saldo)
                    )} */}
                    -
                  </CardContent>
                </Card>
                <div style={{ padding: "0 10px 0 10px" }} />
                <Card className="card-saldo-esign">
                  <CardTitle className="card-title">
                    Total daftar (FR)
                  </CardTitle>
                  <CardContent className="card-content">
                    {loadingTotalUserEsign ? (
                      <CircularProgress centered={false} small={true} />
                    ) : (
                      format(totalTotalUserEsign)
                    )}
                  </CardContent>
                </Card>
                <div style={{ padding: "0 10px 0 10px" }} />
                <Card className="card-saldo-esign">
                  <CardTitle className="card-title">
                    {t.translate("word.totalTopup")}
                  </CardTitle>
                  <CardContent className="card-content">
                    {loadingTotalTopupEsign ? (
                      <CircularProgress centered={false} small={true} />
                    ) : (
                      format(totalTopupEsign)
                    )}
                  </CardContent>
                </Card>
                <div style={{ padding: "0 10px 0 10px" }} />
                <Card className="card-saldo-esign">
                  <CardTitle className="card-title">
                    {t.translate("word.totalStamping")}
                  </CardTitle>
                  <CardContent className="card-content">
                    {loadingStampingEsign ? (
                      <CircularProgress centered={false} small={true} />
                    ) : (
                      format(totalStampingEsign)
                    )}
                  </CardContent>
                </Card>
              </div>
              <CardTitle style={{ paddingTop: 20, paddingBottom: 20 }}>
                Template Dokumen
              </CardTitle>
              <div className="row">
                <Card className="card-saldo-dms">
                  <CardTitle className="card-title">Total Lifetime</CardTitle>
                  <CardContent className="card-content">
                    {loadingDmsOrderTotalLifetime ? (
                      <CircularProgress centered={false} small={true} />
                    ) : (
                      format(totalDmsOrderTotalLifetime)
                    )}
                  </CardContent>
                </Card>
                <div style={{ padding: "0 10px 0 10px" }} />
                <Card className="card-saldo-dms">
                  <CardTitle className="card-title">Total One Time</CardTitle>
                  <CardContent className="card-content">
                    {loadingDmsOrderTotal ? (
                      <CircularProgress centered={false} small={true} />
                    ) : (
                      format(totalDmsOrderTotal)
                    )}
                  </CardContent>
                </Card>
              </div>
              {/* <div className="row">
            <Card
              fullWidth
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                padding: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loadingUsageOrder ? (
                <CircularProgress centered={false} small={true} />
              ) : (
                <Chart
                  title={t.translate("word.orderUsage")}
                  data={dataChart}
                />
              )}
            </Card>
          </div> */}
            </div>
          </>
        }
      />
      <Dialog
        id="simple-dialog"
        visible={visible}
        onRequestClose={disable}
        aria-labelledby="dialog-title"
      >
        <h2
          style={{
            paddingLeft: "20px",
          }}
        >
          {`${t.translate("mpk.column.filter")} ${t.translate(
            "word.orderUsage"
          )}
        `}
        </h2>
        <DataForm
          baseId={`orderUsage-search`}
          defaultData={filter}
          submitIconClassName="mdi mdi-magnify"
          submitLabel={`${t.translate("word.search")}`}
          additionalAction={[
            {
              show: true,
              render: () => (
                <Button
                  style={{ marginRight: 15 }}
                  onClick={() => {
                    setFilter({});
                    fetchUsageOrder();
                    disable();
                  }}
                >
                  {t.translate("word.delete")}
                </Button>
              ),
            },
          ]}
          onSubmit={(values, callback) => {
            try {
              setFilter(values);
              fetchUsageOrder(values);
              callback("", false, false);
              disable();
            } catch (error) {}
          }}
          definitions={[
            {
              inputType: inputTypes.DATEPICKER,
              label: t.translate("word.startDate"),
              key: "startDate",
              type: "text",
            },
            {
              inputType: inputTypes.DATEPICKER,
              label: t.translate("word.endDate"),
              key: "endDate",
              type: "text",
            },
          ]}
        />
      </Dialog>
    </LoadingOverlay>
  );
};

export default inject("authStore")(observer(Dashboard));
