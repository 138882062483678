import { crud, http } from "../../../libs/react-mpk/services";
let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getFeatureOrder = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/feature/order`,
    query,
  });
};

service.getFeatureOrderById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/feature/order/${id}`,
  });
};

service.createFeatureOrderReport = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/feature/order/report`,
    data: data,
  });
};

export default service;
